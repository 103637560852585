ve-media-panel-container {
  width: 100%;
}

.empty-media-panel {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.5rem;
  width: 100%;

  &__image-container {
    height: 8rem;
    margin-bottom: 2rem;
    cursor: pointer;

    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
      pointer-events: none;
    }
  }

  &__headline,
  &__description {
    margin: 0;
    text-align: center;
  }

  &__headline {
    color: #000000;
    font-size: 0.75rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  &__description {
    color: #666666;
    font-size: 0.6875rem;
    font-weight: 400;
    line-height: 1.35;
    margin-bottom: 1.5rem;
  }

  &__file-input {
    display: none;
  }
}

.media-panel-container {
  .media-card__title {
    color: #666666 !important;
  }

  &__file-input {
    display: none;
  }
}
