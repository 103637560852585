.track-context-menu {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10000;
  padding: 0.5rem;
  min-width: 150px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex; /* Use Flexbox to align icon and text */
      align-items: center;
      padding: 0.5rem;
      cursor: pointer;
      font-size: 0.875rem;
      color: #333;
      transition: background-color 0.2s;

      &:hover {
        background-color: #f0f0f0;
      }

      .menu-icon {
        margin-right: 0.5rem; /* Add spacing between icon and text */
        font-size: 1rem; /* Adjust size if needed */
        align-items: center;
        justify-content: center;
        color: #666;
      }

      .menu-text {
        font-weight: normal;

        &--bold {
          font-weight: bold;
          color: #d9534f; /* Optional: Different color for emphasis */
        }
      }
    }
  }
}
