.timeline {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  height: 100%;
  width: 100%;

  &__controls {
    height: 3.5rem;
    margin-top: -1.75rem;
    padding-left: 1rem;
    width: calc(100% - 2rem);
    z-index: 60;
  }

  &__container {
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    background-color: white;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    overflow: hidden;
  }

  &__divider-top {
    position: relative;
    width: 100%;
    margin-bottom: 0.25rem;
  }

  &__void-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: -4px;

    .track-divider {
      top: 0;
      bottom: 0;
      transform: initial;
    }
  }

  &__ruler,
  &__canvas-ruler {
    background-color: white;
    flex-shrink: 0;
    overflow: hidden;
    z-index: 1;
  }

  &__tracks {
    flex-grow: 1;
    overflow: auto;
    position: relative;
    width: 100%;

    .tracks > *:not(:last-child) {
      display: block;
      margin-bottom: 0.375rem;
    }
  }

  &__playhead {
    position: absolute;
    top: 0.45rem;
    bottom: 0;
    height: 100%;
    pointer-events: none;
    transform: translateX(-50%);
    z-index: 9999;
  }
}