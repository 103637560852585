.input {
  display: flex;
  flex-direction: column;

  &__input {
    display: flex;
    padding: 0.5rem;
    border: 1px solid #bfbfbf;
    border-radius: 0.5rem;
    height: 1rem;
    font-size: 0.75rem;
    font-weight: 500;
    background: transparent;
    outline: none;
    color: #000000;

    &:focus {
      border-color: #666666;
    }

    &-error {
      border-color: #ff0000;
    }
  }

  &__error-label {
    padding: 0;
    margin: 0;
    font-size: 0.625rem;
    font-weight: 300;
    padding-top: 0.25rem;
    color: #ff0000;
  }
}
