.effect-showcase {
  &__root {
    position: relative;
    width: 100%;
    height: 3.375rem;
    border-radius: 0.5rem;
    overflow: hidden;
    cursor: pointer;
    background-color: #ffffff;
    box-sizing: border-box;

    &-selected {
      border: 2px solid #008eff;
    }
  }

  &__loading {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    opacity: 0;
    animation: fade-in-spinner 1s ease-in-out forwards;
  }

  &__delete {
    position: absolute;
    z-index: 3;
    right: 0;
    top: 0;
    background-color: rgba(#000, 0.2);
    border-radius: 0.5rem;
    padding: 2px;
    margin: 2px;
    color: #ffffff;
    pointer-events: none;
  }

  &__selected {
    position: absolute;
    z-index: 3;
    right: 0;
    top: 0;
    background-color: #ffffff;
    border-radius: 0.5rem;
    color: #008eff;
    pointer-events: none;
    width: 1rem;
    height: 1rem;
    margin: 2px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image,
  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 1s ease;
    pointer-events: none;
  }

  &__title {
    color: #666666;
    font-size: 0.75rem;
    font-weight: 500;
    margin: 0 0 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &-selected {
      color: #666666;
    }
  }

  &__video {
    z-index: 1;
    opacity: 0;
  }

  &__image {
    opacity: 1;
  }

  @keyframes fade-in-spinner {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
