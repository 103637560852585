.device-list {
    position: absolute;
    display: flex;
    width: 472px;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    border-radius: 12px;
    /* Shadows/X Large */
    box-shadow: 0px 30px 80px 0px rgba(0, 0, 0, 0.15);
}

.device-list li {
    display: flex;
    padding: 16px;
    height: 56px;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
}

.device-list li:last-child {
    border-bottom: none;
}

.device-list .lk-button {
    display: flex;
    width: 100%;
    padding: 12px 16px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
    outline: none;
    justify-content: space-between;
    font-family: Inter, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.14px;
}

.device-list .lk-button:hover {
    background-color: #f0f0f0;
}

.device-list li.active .lk-button {
    background-color: #e6f7ff;
    color: #007bff;
}

.device-list li.active .lk-button::after {
    content: "✔"; /* Checkmark for the active item */
    float: right;
    font-size: 16px;
    color: #007bff;
}