.panel {
  box-sizing: border-box;
  display: inline-flex;
  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  overflow: hidden auto;
  padding-bottom: 7rem;

  border-radius: 0.5rem;
  border: 1px solid #eeeeee;
  background: #ffffff;

  width: 100%;
  max-height: 500px;
  height: 500px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-width: 250px;
  }
}
