.paper {
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid #eeeeee;
  background: #ffffff;
  box-sizing: border-box;

  &__row {
    padding: 0.625rem 1rem;
    box-shadow: 0px 8px 24px 0px rgba(#eeeeee, 0.04);
  }

  &__column {
    padding: 1rem 0.625rem;
    box-shadow: 4px 0px 24px 0px rgba(#eeeeee, 0.04);
  }
}
