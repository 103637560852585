.pk_contextMenu{
    position:absolute;
    z-index:99999;
}

.pk_contextMenu div{ position:relative; }

.pk_contextMenu{
    background: #111;
    border: 1px solid #444;
    font-family:Arial, serif, sans-serif;
    font-size:14px;
    margin: 1px 0 0;
    min-width: 150px;
    padding: 4px 0;
    color:#111;
    z-index: 8;
    border-radius:3px;
    box-shadow: 0 2px 12px rgba(138, 58, 138, 0.5);
    background-clip: padding-box;
}
.pk_contextMenu a, .pk_contextMenu a:hover{
    clear: both;
    color: #eee;
    display: block;
    font-weight: normal;
    line-height: 18px;
    margin:2px 5px;
    padding: 5px 8px;
    font-size:13px;
    white-space: nowrap;
    cursor:pointer;
    font-family:Arial, serif, sans-serif;
    font-weight:normal;
    transition: all 110ms;
}
.pk_contextMenu a:hover{
    color:#99c2c6;
    line-height: 18px;
    text-decoration: none;
}