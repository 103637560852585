/* videoPlayer.css */
.video-container {
    position: relative;
    overflow: hidden;
    background: black;
}

.aspect-16-9 {
    width: 95%;
    max-width: 854px;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
}

.aspect-9-16 {
    width: 35%;
    padding-bottom: 65.78%; /* 9:16 aspect ratio */
}

.aspect-1-1 {
    width: 60%;
    padding-bottom: 60%; /* 1:1 aspect ratio */
}

.dark-theme {
    background: black;
}

.ligth-theme {
    background: black;
}

.video-container video {
    position: absolute;
    top: 50%;
    left: 50%;
    height: auto;
    transform: translate(-50%, -50%);
}
