.empty-timeline-button {
  position: absolute;
  left: 5%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9000;

  align-items: center;
  background-color: #eeeeee;
  border: 2px solid #eeeeee;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(#ffffff, 0.1);
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  height: 40px;
  justify-content: center;
  width: 90%;
  color: #999999;

  &__text {
    color: #999999;
    font-size: 0.75rem;
    margin: 0;
  }

  &__file-input {
    display: none;
  }

  &__dragging-over {
    border-color: #008eff;
  }
}
