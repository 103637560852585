.label {
  font-size: 0.75rem;
  font-weight: 500;
  color: #666666;
  padding-bottom: 0.5rem;

  &-error {
    color: #ff0000;
  }
}
