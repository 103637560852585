.select {
  display: flex;
  flex-direction: column;

  &__variant-border &__content {
    border: 1px solid #bfbfbf;
  }

  &__content {
    border-radius: 0.5rem;
    display: inline-block;
    position: relative;
  }

  &__btn {
    width: 100%;
    color: #000000 !important;
    background: #ffffff !important;
    &-icon {
      font-size: 0;
      margin-left: auto;
      padding-left: 0.25rem;
    }
  }

  &__button {
    cursor: pointer;
  }

  &__dropdown {
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    font-weight: 500;
    gap: 0.125rem;
    list-style-type: none;
    padding: 0.625rem;
    position: absolute;
    white-space: nowrap;
    z-index: 9999;

    top: 1.5rem;
    left: 0;

    max-height: 200px;
    overflow: auto;
  }
}
