.card-loading-overlay {
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.25);
  height: 100%;
  display: grid;
  left: 0;
  place-items: center;
  position: absolute;
  top: 0;
  width: 100%;

  &__icon {
    animation: spin 4s linear infinite;
    background-color: rgba(241, 241, 241, 0.35);
    border-radius: 100%;
    display: grid;
    filter: drop-shadow(0 4px 16px rgba(255, 255, 255, 0.5));
    font-size: 0;
    height: 1.5rem;
    place-items: center;
    width: 1.5rem;

    svg {
      height: 1rem;
      user-select: none;
      width: 1rem;
    }

    @keyframes spin {
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
}
