.track {
  position: relative;
  width: 100%;

  &__content {
    position: relative;
    display: flex;
    height: 2.25rem;
    background-color: #eeeeee;
    border-radius: 0.5rem;
  }

  &__drop-clip-placeholder {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100px;
    border: 2px dashed #008eff;
    pointer-events: none;
    box-sizing: border-box;
    border-radius: 0.5rem;
    visibility: hidden;
  }
}
