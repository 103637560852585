.info-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.5rem 0;
  width: 100%;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    color: #666666;
    //color: var(--accent);
  }

  &__title,
  &__description {
    margin: 0;
    text-align: center;
  }

  &__title {
    color: var(--text);
    font-size: 0.75rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  &__description {
    color: #666666;
    //color: var(--accent);
    font-size: 0.6875rem;
    font-weight: 400;
    line-height: 1.35;
    margin-bottom: 1.5rem;
  }
}
