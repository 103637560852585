.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  &__section {
    display: flex;
    gap: 0.625rem;
    align-items: center;

    &__audio-range {
      max-width: 3.5rem;
    }

    &--stop-btn {
      width: 12px;
      height: 12px;
      background-color: #666666;
      border-radius: 2px;
    }
  }

  &__playback__time {
    color: #000000;
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-variant-numeric: tabular-nums;
    font-weight: 500;
    line-height: normal;
  }
}
