ve-track-divider {
  width: 100%;
}

.track-divider {
  position: absolute;
  width: 100%;
  z-index: 100;
  bottom: 0rem;
  padding: 0.1rem 0rem;
  transform: translateY(100%);

  &__content {
    opacity: 0;
    position: relative;
    width: 100%;
    height: 3px;
    border-radius: 50px;
    background-color: #008eff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    position: absolute;
    top: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #008eff;
    box-shadow: 0px 0px 21px 3px rgba(0, 0, 0, 0.1);
    color: #ffffff;
  }

  &__visible {
    opacity: 1;
  }
}
