ve-clip {
  width: auto;
  height: auto;
}

:host {
  --clip-height: 2.25rem;
}

:root {
  --clip-background-color: #2b62fd;
  --border-width: 0.125rem;
}


.clip {
  --border-width: 0.125rem;
  --border-radius: 0.5rem;

  cursor: pointer;
  height: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 1;
  min-width: 20px;

  background: linear-gradient(
                  0deg,
                  rgba(var(--clip-background-color), 0.4) 0%,
                  rgba(var(--clip-background-color), 0) 100%
  ),
  rgba(var(--clip-background-color), 0.4);

  border-radius: var(--border-radius);
  box-sizing: border-box;
  box-shadow: inset 0px 0px 0px var(--border-width) rgba(var(--clip-background-color)); // to simulate the border

  transition:
          border-color 0.2s ease-in-out,
          background-color 0.2s ease-in-out;

  &__content {
    margin: 0.5rem 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.38rem;

    &__text {
      @extend .text-elipsis !optional;

      color: #ffffff;
      font-size: 0.625rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      z-index: 1;
      text-shadow: 0px 0px 1px #000000;
    }

    &__icon {
      color: #ffffff;
      z-index: 1;
    }

    &__focused,
    &:hover {
      filter: drop-shadow(0, 0375rem, 1rem, rgba(var(--clip-background-color), 0.85));
    }
  }
  &__moveable {
    --control-width: 0.75rem;

    height: 100%;

    .moveable-line {
      width: 0px !important;
      height: 0px !important;
      background: none !important;
    }

    .moveable-line[data-line-key="render-line-0"],
    .moveable-line[data-line-key="render-line-2"] {
      background-color: rgb(var(--clip-background-color)) !important;
      height: 100% !important;
      width: var(--control-width) !important;
    }

    .moveable-line[data-line-key="render-line-0"] {
      border-radius: 0.5rem 0 0 0.5rem;
      left: 0.5px !important;
      top: 50%;
    }

    .moveable-line[data-line-key="render-line-2"] {
      border-radius: 0.5rem 0 0 0.5rem;
      left: -0.5px !important;
      top: -50%;
    }

    .moveable-line[data-line-key="render-line-0"]::after,
    .moveable-line[data-line-key="render-line-0"]::before,
    .moveable-line[data-line-key="render-line-2"]::after,
    .moveable-line[data-line-key="render-line-2"]::before {
      --size: 0.25rem;

      background-color: transparent;
      content: "";
      height: var(--size);
      position: absolute;
      width: var(--size);
    }

    .moveable-line[data-line-key="render-line-0"]::after,
    .moveable-line[data-line-key="render-line-2"]::after {
      border-radius: 0 0 0 100vw;
      box-shadow: -1px 1px 0 1px rgba(var(--clip-background-color));
    }

    .moveable-line[data-line-key="render-line-0"]::before,
    .moveable-line[data-line-key="render-line-2"]::before {
      border-radius: 100vw 0 0 0;
      box-shadow: -1px -1px 0 1px rgba(var(--clip-background-color));
    }

    .moveable-line[data-line-key="render-line-2"]::after,
    .moveable-line[data-line-key="render-line-2"]::before {
      left: calc(var(--size) * -1);
    }

    .moveable-line[data-line-key="render-line-2"]::after {
      bottom: 2px;

      left: 100% !important;
    }

    .moveable-line[data-line-key="render-line-2"]::before {
      left: 100% !important;
      top: 2px;
    }

    .moveable-line[data-line-key="render-line-0"]::after,
    .moveable-line[data-line-key="render-line-0"]::before {
      right: calc(var(--size) * -1) !important;
    }

    .moveable-line[data-line-key="render-line-0"]::after {
      bottom: 2px;
    }

    .moveable-line[data-line-key="render-line-0"]::before {
      left: auto;
      top: 2px;
    }

    .moveable-direction.moveable-e,
    .moveable-direction.moveable-w {
      background: none !important;
      border: 0;
      border-radius: 0;
      height: 100%;
      margin-top: calc(var(--clip-height) / 2 * -1);
      position: absolute;
      width: var(--control-width);
    }

    .moveable-direction.moveable-w {
      margin-left: 0 !important;
    }

    .moveable-direction.moveable-e {
      margin-left: calc(var(--control-width) * -1 - 0.5px) !important;
    }

    .moveable-direction.moveable-e::after,
    .moveable-direction.moveable-w::after {
      --height: 0.625rem;
      --width: 0.125rem;

      background-color: #ffffff;
      border-radius: var(--width);
      content: "";
      height: var(--height);
      position: absolute;
      left: calc(50%);
      top: 50%;
      transform: translate(-50%, -50%);
      width: var(--width);
    }

    .moveable-direction {
      box-sizing: border-box;
      position: absolute;
    }
  }

  &__predecessor {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  &__successor {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &__thumbnail-container {
    container-type: inline-size;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    padding: var(--border-width);
    width: 100%;
  }

  &__overlay {
    position: absolute;
    height: calc(100% - var(--border-width) * 2);
    width: calc(100% - var(--border-width) * 2);
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__thumbnail {
    height: 100%;
    width: 100%;
    background-size: contain;
    background-repeat: repeat-x;
  }

  &__overlay,
  &__thumbnail {
    border-radius: calc(var(--border-radius) - var(--border-width));
  }

  @container (max-width: 120px) {
    &__thumbnail {
      background-size: cover;
    }
  }
}

.clip__predecessor + .clip__moveable .moveable-line[data-line-key="render-line-0"],
.clip__successor + .clip__moveable .moveable-line[data-line-key="render-line-2"] {
  border-radius: 0 !important;
}

.clip__predecessor .clip__overlay,
.clip__predecessor .clip__thumbnail {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.clip__successor .clip__overlay,
.clip__successor .clip__thumbnail {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
