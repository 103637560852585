.box-item {
    background-color: #faf8f7;
    transition: background-color 0.3s ease;
    border-radius: 20px;
}

.box-item:hover {
    background-color: #edeceb;
}

.image-box {
    background-color: #cccccc; /* Placeholder for the image area */
}
