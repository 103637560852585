ve-panel-grid-list-section {
  width: 100%;
}

.panel-grid-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-section-text {
      color: var(--text);
      font-size: 0.75rem;
      font-weight: 500;
    }

    &-action-text {
      display: flex;
      align-items: center;
      color: var(--accent);
      font-size: 0.75rem;
      font-weight: 500;
    }
  }

  &__content {
    display: grid;

    gap: 0.5rem;

    grid-template-columns: repeat(var(--columns), 1fr);

    @media (max-width: 768px) {
      // Adjust for smaller screens
      grid-template-columns: repeat(var(--columns), 1fr);
    }
  }
}
