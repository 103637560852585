ve-composition {
  flex: 1;
  min-width: 0;
  position: relative;
  z-index: 1;
  border: 1px solid black;
}

.composition {
  align-items: center;
  //box-shadow: 0px 8px 32px 32px rgba(#000000, 0.04);
  box-sizing: border-box;
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: center;
  padding: 2rem calc(2rem + 1.75rem);
  position: relative;
  width: 100%;

  &__container {
    position: relative;
    display: grid;
    height: 100%;
    place-content: center;
    width: 100%;
    //max-width: 40vw;
    //max-height: 720px;

    canvas {
      width: 100%;
      height: auto;
    }
  }
}
