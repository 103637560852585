.avatars {
    display: flex;
    padding: 0 0.75rem;
}

.avatar {
    display: flex;
    place-content: center;
    position: relative;
    border: 4px solid #fff;
    border-radius: 9999px;
    width: 42px;
    height: 42px;
    background-color: #9ca3af;
    margin-left: -0.75rem;
}

.avatar:before {
    content: attr(data-tooltip);
    position: absolute;
    top: 100%;
    opacity: 0;
    transition: opacity 0.15s ease;
    padding: 5px 10px;
    color: white;
    font-size: 0.75rem;
    border-radius: 8px;
    margin-top: 10px;
    z-index: 1;
    background: black;
    white-space: nowrap;
}

.avatar:hover:before {
    opacity: 1;
}

.avatar_picture {
    width: 100%;
    height: 100%;
    border-radius: 9999px;
}
