.clean {
    background-color: #444;
    color: white;
    padding: 1px 4px 1px 4px;
    border-radius: 2px;
    font-weight: normal;
    font-size: 20px;
}

.bold {
    color: white;
    font-family: Oswald, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.14px;
    display: flex;
    padding: 1px 4px 1px 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    background-color: #2B6BFD;
}

.edgy {
    display: flex;
    padding: 1px 4px 1px 4px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 2px;
    background-color: #1a1a1a;
    color: white;
    font-family: "Patrick Hand", serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.14px;
}

.classic {
    color: #1a1a1a;
    background-color: white;
    font-family: "Noto Serif", serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.14px;
    padding: 0px 4px;
}

.fun {
    display: flex;
    padding: 0px 8px;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #2b6bfd;
    color: white;
    font-family: "Bowlby One", serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.14px;
}
