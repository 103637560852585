.transition {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  z-index: 999999;
  width: 24px;
  height: 24px;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    border-radius: 4px;
    color: #ffffff;
    border: 2px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;

    > svg {
      width: 12px;
      height: 12px;
    }
  }

  &__permanent {
    background-color: #ffa928;
  }

  &__temporary {
    background-color: #7664e9;
  }

  &__visible {
    visibility: visible;
  }

  &__dragging-over {
    width: 100%;
    height: 100%;

    > svg {
      width: 12px;
      height: 12px;
    }
  }

  &__selected {
    border-color: red;
  }
}
