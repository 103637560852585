.custom-scrollbar {
    cursor: pointer;
    position: absolute;
    top: var(--top);
    left: var(--left);
    width: 310px;
    height: 500px;
    padding: 10px;
    gap: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 16px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    overflow: visible;
    overflow-y: auto;
    z-index: 1000;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
