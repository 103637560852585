.libretto-participant-metadata-item {
    display: flex;
    align-items: center;
    padding: .25rem;
    background-color: transparent;
    border-radius: calc(var(--lk-border-radius) / 2)
}

.libretto-participant-top-item {
    position: absolute;
    right: .25rem;
    height: 150px;
    width: 150px;
    background: transparent;
    top: .25rem;
    left: .25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    line-height: 1
}
