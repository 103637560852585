.playhead {
  position: sticky;
  height: 100%;
  display: inline-block;
  top: 0rem;
  box-sizing: border-box;
  flex-shrink: 0;
  pointer-events: all;
  cursor: grab;

  &__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  &__marker {
    font-size: 0;
    top: 0;

    svg {
      filter: drop-shadow(0px 2px 4px rgba(117, 117, 117, 0.35));

      path {
        stroke: #757575;
      }
    }
  }

  &__handle {
    background-color: #757575;
    filter: drop-shadow(0px 0px 8px rgba(117, 117, 117, 0.5));
    height: 100%;
    transform: translateY(-0.025rem);
    width: 0.0625rem;
  }

  &__moveable {
    .moveable-line {
      background-color: transparent !important;
    }
  }
}
