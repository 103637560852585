.btn {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  outline: none;
  background: none;
  border: 0;
  cursor: pointer;

  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  text-align: center;

  color: #666666;

  &:disabled {
    background-color: #707070;
  }

  &__ghost {
    &:hover {
      background-color: #ffffff;
    }

    &:disabled {
      background-color: transparent;
      color: #707070;
    }
  }

  &__default {
    background-color: #fafafa;
  }

  &__primary {
    background: #008eff;
    box-shadow: 0px 4px 8px 0px rgba(#008eff, 0.3);

    color: #ffffff;
    transition: box-shadow 0.2s ease-in;

    &:hover {
      box-shadow: 0px 4px 8px 0px rgba(#008eff, 0.5);
      transition: box-shadow 0.2s ease-out;
    }
  }

  &:disabled {
    cursor: not-allowed;
    box-shadow: none;
  }
}
