.option {
  align-items: center;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  padding: 0.5rem 0.75rem;
  color: #000000;

  &:focus,
  &:hover {
    background-color: var(--surface-dark);
  }
}
