.searchbar {
  display: flex;
  align-items: center;
  flex: 5;
  padding: 0.5rem;
  border: 1px solid #bfbfbf;
 // border: 1px solid var(--surface-light);
  border-radius: 0.5rem;
  color: var(--text);

  &__input {
    flex: 4;
    height: 1rem;
    font-size: 0.75rem;
    font-weight: 500;
    border: none;
    outline: none;
    background: transparent;
    color: var(--text);
  }

  &:has(> input:focus) {
    border-color: #666666;
    //border-color: var(--accent);
  }
}
