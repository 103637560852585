.color-picker {
  position: relative;
  width: 10px;
  height: 10px;
  cursor: pointer;

  border-radius: 0.25rem;
  border: 2px solid #bfbfbf;
  background: transparent;

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}
